<!-- app首页设置 -->
<template>
	<div>
<!-- 		<div style="margin-top: 20px">
		    <el-radio-group v-model="app_index_category_id" size="small" @change="getData">
				<el-radio-button v-for="(item,index) in appIndexCategory" :key="index" :label="item.id">{{ item.name }}</el-radio-button>
		    </el-radio-group>
			
			<el-button type="text" style="padding-left: 10px;" @click="openModel(false)">+编辑</el-button>
		</div> -->
		
		<div class="row">
			<div class="col-3">
				<div class="card">
					<div class="card-header bg-white">
						<span>基础组件</span>
					</div>
					<div class="card-body d-flex flex-wrap">
						<div v-for="(item,index) in moduleList"  @click="addData(item)" :key="index" style="width: 30%;background: #f7f7f7;border-radius: 8px;" class="d-flex flex-column align-items-center justify-content-center m-1">
							<img :src="item.urls" style="width: 36px;height: 36px;" class="mt-2"/>
							<span class="mb-2 mt-1" style="font-size: 10px;color: #6c6c6c;">{{item.name}}</span>
						</div>
					</div>	
				</div>
			</div>	
			<div class="col-4">
				<div class="card" v-loading="cardLoading">
					<div class="card-header bg-white">
						<span>小程序示例</span>
					</div>
					<div class="card-body p-3" style="overflow-y:scroll;height: 75vh;">
						<div class="text-center py-5 text-muted" v-if="!indexList.length">
							暂无数据
						</div>
						<div class="mb-2" v-for="(l,li) in indexList" :key="li" :class="activeId === l.id ? 'app-index-item-active' : ''" @click="choose(l.id)" style="position: relative;">
							
							<!-- 轮播图组件 -->
							<el-carousel height="30vh" v-if="l.type === 'swiper'">
								<div class="w-100 text-muted text-center py-5 border" v-if="!l.data[0]">
									点击添加轮播图
								</div>
								<el-carousel-item v-for="(item,index) in l.data" :key="index">
									<img :src="item.src" style="width: 100%;">
								</el-carousel-item>
							</el-carousel>
							
							<!-- 辅助空白组件 -->
							<el-carousel height="10px" v-else-if="l.type === 'fzkb'">
							</el-carousel>
							
							<!-- 辅助线组件 -->
							<el-carousel height="0.2px" v-else-if="l.type === 'fzx'">
								<div style="width: 100%;height: 0.2px;background: #9f9f9f;"></div>
							</el-carousel>
							
							<!-- 图标分类组件 -->
							<div v-else-if="l.type === 'indexnavs'" class="d-flex flex-wrap">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加图标分类
								</div>
								<div v-for="(item,index) in l.data" :key="index" style="width: 20%;padding-bottom: 20px;" class="d-flex flex-column align-items-center justify-content-center">
									<img :src="item.src" style="width: 44px;height: 44px;"/>
									<span class="my-1" style="font-size: 10px;">{{item.text}}</span>
								</div>
							</div>
							
							
							<!-- 四图广告位 threeAdv-->
							<div v-else-if="l.type === 'threeAdv'" class="d-flex flex-wrap">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加图片广告
								</div>
								<div class="d-flex w-100">
									<img v-if="l.data[0]" :src="l.data[0].src" style="width: 50%; height: 278px;border-radius: 10px;margin-right: 5px;">
									<div style="width: 50%;" class="d-flex flex-column">
										<img v-if="l.data[1]" :src="l.data[1].src" style="height: 89px;margin-left: 5px;margin-bottom: 5px;">
										<img v-if="l.data[2]" :src="l.data[2].src" style="height: 89px;margin-left: 5px;margin-bottom: 5px;">
										<img v-if="l.data[3]" :src="l.data[3].src" style="height: 89px;margin-left: 5px;">
									</div>
								</div>
							</div>
							
							<!-- 三图广告位 -->
							<div v-else-if="l.type === 'fourAdv'" class="d-flex flex-wrap">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加图片广告
								</div>
								<img v-if="l.data[0]" :src="l.data[0].src" style="width: 31%; height: 90px;margin-right: 3%;">
								<img v-if="l.data[1]" :src="l.data[1].src" style="width: 31%; height: 90px;margin-right: 3%;">
								<img v-if="l.data[2]" :src="l.data[2].src" style="width: 31%; height: 90px;">
							</div>
							
							<!-- 双图广告位 -->
							<div v-else-if="l.type === 'twoAdv'" class="d-flex flex-wrap">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加图片广告
								</div>
								<img v-if="l.data[0]" :src="l.data[0].src" style="width: 49%; height: 90px;margin-right: 2%;">
								<img v-if="l.data[1]" :src="l.data[1].src" style="width: 49%; height: 90px;">
							</div>
							
							<!-- 大图广告位 -->
							<div v-else-if="l.type === 'oneAdv'" class="d-flex flex-wrap">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加图片广告
								</div>
								<img v-if="l.data[0]" :src="l.data[0].src" style="width: 100%; height: 220px;">
							</div>
							
							<!-- 标题栏 -->
							<div v-else-if="l.type === 'btl'" class="d-flex" style="align-items: center;">
								<img :src="l.data[0].src" style="width: 20px;height: 20px;" class="mr-1">
								<div style="font-weight: 800;">{{l.data[0].name}}</div>
							</div>
							
							
							<!-- 商品列表 -->
							<div v-else-if="l.type === 'list'" class="row">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加商品
								</div>
								<div class="col-6 px-1" v-for="(item,index) in l.data" :key="index">
									<div class="mb-2" style="background-color: #f8f8f8;border-radius: 10px;">
										<img :src="item.cover" style="width: 100%;height: 190px;border-top-left-radius: 10px;border-top-right-radius: 10px;" class="bg-light">
										<div class="p-1">
											<h6 class="mb-1" style="font-weight: 800;">{{item.title}}</h6>
											<p class="small mb-1 text-secondary">{{item.desc}}</p>
											<span class="text-danger" style="color: #000000; font-size: 18px;font-weight: 600;">￥{{item.pprice}}</span>
											<span class="text-muted ml-2 small">￥{{item.oprice}}</span>
											
										</div>
									</div>
								</div>
							</div>
							
							<!-- 商品列表 1行1个 -->
							<div v-else-if="l.type === 'listTwo'" class="row">
								<div class="w-100 text-muted text-center py-5" v-if="!l.data[0]">
									点击添加商品
								</div>
								<div class="col-12 px-1" v-for="(item,index) in l.data" :key="index">
									<div class="border mb-2 d-flex">
										<img :src="item.cover" style="width: 150px;height: 150px; margin: 0 5px 0 8px; margin-top: 8px;" class="bg-light">
										<div class="p-1">
											<h6 class="mb-1">{{item.title}}</h6>
											<p class="small mb-1 text-secondary">{{item.desc}}</p>
											<div style="position: relative; top: 60px; left:0; bottom:0;">
												<span class="text-danger">￥{{item.pprice}}</span>
												<span class="text-muted ml-2 small">￥{{item.oprice}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<!-- 按钮 -->
							<div v-if="activeId === l.id" class="app-index-icon-box">
								<span class="border app-index-icon-btn" :class="li === 0 ? 'app-index-icon-btn-disabled' : ''" @click="moveUp(li)">
									<i class="el-icon-arrow-up"></i>
								</span>
								<span class="border app-index-icon-btn" :class="(li === (indexList.length - 1)) || indexList.length === 0 ? 'app-index-icon-btn-disabled' : ''" @click="moveDown(li)">
									<i class="el-icon-arrow-down"></i>
								</span>
								<span class="border app-index-icon-btn" @click="deleteItem(li)">
									<i class="el-icon-delete"></i>
								</span>
							</div>
							
						</div>
						
						
						
					</div>
				</div>

			</div>
			
			<div class="col-5">
				<div class="card" v-if="editItem">
					<div class="card-header bg-white">
						<span>配置</span>
						<el-button style="float: right;" type="primary" size="mini" @click="submit">保存</el-button>
					</div>
					<div class="card-body p-3" style="overflow-y:scroll;height: 75vh;">
						<!-- 配置轮播图 -->
						<form v-if="editItem.type === 'swiper'">
							<div class="form-group" v-for="(item,index) in swiper.data" :key="index">
								<div class="card">
									<div class="card-header">
										<el-button style="float: right; padding: 3px 0" type="text" @click="deleteFormItem(index)">删除</el-button>
									</div>
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;">	</i>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 辅助空白 -->
						<form v-if="editItem.type === 'fzkb'">
							<div style="height: 20px;" class="mb-3">
								这是辅助空白
							</div>
						</form>
						
						<!-- 辅助线 -->
						<form v-if="editItem.type === 'fzx'">
							<div style="height: 20px;" class="mb-3">
								这是辅助线
							</div>
						</form>
						
						<!-- 配置图标分类 -->
						<form v-if="editItem.type === 'indexnavs'">
							<div class="form-group" v-for="(item,index) in indexnavs.data" :key="index">
								<div class="card">
									<div class="card-header">
										<el-button style="float: right; padding: 3px 0" type="text" @click="deleteFormItem(index)">删除</el-button>
									</div>
									<div class="card-body p-3 d-flex align-items-center">
										<div style="width: 35px;height: 35px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center mr-3" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="width: 35px;height: 35px;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
										<input type="text" class="form-control" v-model="item.text" placeholder="填写分类名称">
									</div>
									<el-select v-model="value[index]" placeholder="请选择关联的页面">
									    <el-option
											v-for="(item,index) in options" :key="item.index"
											:label="item.name"
											:value="item.url">
									    </el-option>
									</el-select>
								</div>
							</div>
						</form>
						
						<!-- 四图广告位 -->
						<form v-if="editItem.type === 'threeAdv'">
							<div class="form-group" v-for="(item,index) in threeAdv.data" :key="index">
								<div class="card">
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 三图广告位 -->
						<form v-if="editItem.type === 'fourAdv'">
							<div class="form-group" v-for="(item,index) in fourAdv.data" :key="index">
								<div class="card">
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 双图广告 -->
						<form v-if="editItem.type === 'twoAdv'">
							<div class="form-group" v-for="(item,index) in twoAdv.data" :key="index">
								<div class="card">
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 大图广告 -->
						<form v-if="editItem.type === 'oneAdv'">
							<div class="form-group" v-for="(item,index) in oneAdv.data" :key="index">
								<div class="card">
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 标题 -->
						<form v-if="editItem.type === 'btl'">
							<div class="form-group" v-for="(item,index) in btl.data" :key="index">
								<div class="card">
									<div class="card-body p-0">
										<div style="width: 100%;height: 100px;cursor: pointer;position: relative;" class="d-flex align-items-center justify-content-center" @click="chooseImage(index)">
											<img v-if="item.src" :src="item.src" style="height: 100%;"/>
											<i v-else class="el-icon-plus text-muted" style="font-size: 30px;"></i>
										</div>
									</div>
								</div>
								<el-input v-model="item.name" placeholder="请输入新标题名称" size="mini" style="width: 100%;" class="mt-2"></el-input>
							</div>
						</form>
						
						<!-- 商品列表 -->
						<form v-if="editItem.type === 'list'">
							<div class="form-group">
								<div class="row">
									<div class="col-2 px-1" v-for="(item,index) in list.data" :key="index">
										<div class="border mb-1 position-relative">
											<span class="border bg-white px-2 py-1" style="position: absolute;right: 0;top: 0;cursor: pointer;" @click="deleteFormItem(index)"><i class="el-icon-delete"></i></span>
											<img :src="item.cover" style="width: 60px;height: 60px;" class="bg-light">
											<div class="p-1">
												<!-- <h6 class="mb-1">{{item.title}}</h6> -->
												<!-- <p class="small mb-1 text-secondary">{{item.desc}}</p> -->
												<!-- <span class="text-danger">￥{{item.pprice}}</span> -->
												<!-- <span class="text-muted ml-2 small">￥{{item.oprice}}</span> -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<!-- 商品列表  1行1个 -->
						<form v-if="editItem.type === 'listTwo'">
							<div class="form-group">
								<div class="row">
									<div class="col-2 px-1" v-for="(item,index) in listTwo.data" :key="index">
										<div class="border mb-1 position-relative">
											<span class="border bg-white px-2 py-1" style="position: absolute;right: 0;top: 0;cursor: pointer;" @click="deleteFormItem(index)"><i class="el-icon-delete"></i></span>
											<img :src="item.cover" style="width: 60px;height: 60px;" class="bg-light">
											<div class="p-1">
												<!-- <h6 class="mb-1">{{item.title}}</h6> -->
												<!-- <p class="small mb-1 text-secondary">{{item.desc}}</p> -->
												<!-- <span class="text-danger">￥{{item.pprice}}</span> -->
												<!-- <span class="text-muted ml-2 small">￥{{item.oprice}}</span> -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
						
						<button v-if="editItem.type !== 'three'" type="submit" class="btn btn-outline-secondary w-100" @click="addFormItem" :disabled="buttonItem.data.length === buttonItem.limit">
							+ 添加 {{buttonItem.data.length}}/{{buttonItem.limit}}
							<div v-if="editItem.type === 'swiper'" style="color: #000000;opacity: 0.3;"> (图片尺寸控制在640*385)</div>
							<div v-if="editItem.type === 'threeAdv'" style="color: #000000;opacity: 0.3;"> (竖图320*506  横图315*164)</div>
							<div v-if="editItem.type === 'twoAdv'" style="color: #000000;opacity: 0.3;"> (上图640*307  下图640*118)</div>
							<div v-if="editItem.type === 'btl'" style="color: #000000;opacity: 0.3;"> (小图标20*20)</div>
						</button>
					</div>
				</div>
			</div>
			
			
		</div>
		
		<goods-dialog ref="goodsDialog"></goods-dialog>
		
		<!-- 新增/修改模态框 -->
		<el-dialog title="顶层页面" :visible.sync="createModels" top="150px" style="text-align: center;">
			<!-- 表单内容 -->
			<el-form ref="form" :model="form" style="display: block;">
				<!-- 用户名 -->
				<el-form-item v-for="(item, index) in appIndexCategory" :key="index">
					<el-input v-model="item.name" placeholder="请输入您的姓名(必填)" size="mini" style="width: 30%;"></el-input>
					<el-button type="success" plain style="margin-left: 10px;" size="mini" @click="updateindex(item.name, item.id)">修改</el-button>
					<el-button type="danger" size="mini" @click="deleteindex(index, item.id)">删除</el-button>
				</el-form-item>
				<el-button type="text" style="padding-left: 10px;" @click="saveindex">+新增顶层页面</el-button>
			</el-form>
		
			<div slot="footer" class="dialog-footer">
				<el-button @click="createModels = false">取 消</el-button>
				<el-button type="primary" @click="submits">确 定</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import $U from '@/common/util.js'; // 引入 sku排列算法
	import goodsDialog from '@/components/shop/goods-dialog.vue'; // 引入 商品管理-组件
	export default {
		components: {
			goodsDialog
		},
		inject:['app','layout'],
		data() {
			return {
				options: [
					{
						name:'首页',
						url:'index/index'
					},{
						name:'菜单',
						url:'class/class'
					},{
						name:'订单',
						url:'cart/cart'
					},{
						name:'我的',
						url:'my/my'
					},
				],
				value: [],
				activeId: 0,
				appIndexCategory:[],
				app_index_category_id:1,
				swiper:{
					limit:5,
					data:[]
				},
				indexnavs:{
					limit:10,
					data:[]
				},
				threeAdv:{
					limit:4,
					data:[]
				},
				oneAdv:{
					limit:1,
					data:[]
				},
				twoAdv:{
					limit:2,
					data:[]
				},
				fourAdv:{
					limit:3,
					data:[]
				},
				list:{
					limit:8,
					data:[]
				},
				listTwo:{
					limit:8,
					data:[]
				},
				fzkb:{
					limit:0,
					data:[]
				},
				fzx:{
					limit:0,
					data:[]
				},
				btl:{
					limit:1,
					data:[]
				},
				grxx:{
					limit:1,
					data:[]
				},
				moduleList:[{
					name:"轮播图",
					type:"swiper",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/63725615534cd.png',	
					data:[]
				},{
					name:"图标分类",
					type:"indexnavs",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/6372561559c54.png',
					data:[]
				},
				{
					name:"标题栏",
					type:"btl",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256154fae9.png',
					data:[]
				},
				{
					name:"单图",
					type:"oneAdv",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/6372561551a74.png',
					data:[]
				},{
					name:"双图",
					type:"twoAdv",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256154a8a2.png',
					data:{
						title: "",
						cover: ""
					}
				},{
					name:"三图",
					type:"fourAdv",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/6372561551650.png',
					data:[]
				},{
					name:"四图",
					type:"threeAdv",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256155147f.png',
					data:[]
				},{
					name:"辅助空白",
					type:"fzkb",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256154de11.png',
					data:[]
				},{
					name:"辅助线",
					type:"fzx",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/63725615621f9.png',
					data:[]
				},
				// {
				// 	name:"个人信息",
				// 	type:"grxx",
				// 	urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256155f9e3.png',
				// 	data:[]
				// },
				{
					name:"商品列表",
					type:"list",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/637256150c4c9.png',
					data:[]
				},{
					name:"商品列表2",
					type:"listTwo",
					urls:'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/63725615519cf.png',
					data:[]
				}],
				form: {
					name: '',
					phone: '',
					weixin: '',
					title: '',
					qq: ''
				},
				indexList: [], // app首页列表
				cardLoading:false,
				editIndexx: -1,
				createModels: false,
			}
		},
		computed: {
			buttonItem(){
				return this[this.editItem.type]
			},
			editIndex(){
				return this.indexList.findIndex((item)=>item.id === this.activeId)
			},
			editItem(){
				return this.editIndex === -1 ? false : this.indexList[this.editIndex]
			}
		},
		created() {
			this.__init()
		},
		methods: {
			__init(){
				this.layout.showLoading()
				this.axios.get('/admin/app_index_category/list',{ //顶部选显卡api
					token:true
				}).then(res=>{
					this.appIndexCategory = res.data.data
					this.getData()
				}).finally(()=>{
					this.layout.hideLoading()
				})
			},
			// 获取数据
			getData(){
				this.cardLoading = true
				this.axios.get('/admin/app_index_data/list?app_index_category_id='+this.app_index_category_id,{
					token:true
				}).then(res=>{
					this.indexList = res.data.data // app首页列表
				}).finally(()=>{
					this.cardLoading = false
				})
			},
			// 创建新组件
			addData(item){
				this.cardLoading = true
				this.axios.post('/admin/app_index_data',{
					type:item.type,
					order:50,
					data:JSON.stringify(item.data),
					app_index_category_id:this.app_index_category_id
				},{
					token:true
				}).then(res=>{
					// console.log(res)
					let d = res.data.data // 给首页 创建新组件
					d.data = JSON.parse(d.data)
					this.indexList.push(d) // 给 原来的首页添加 新组件
					this.$message.success('添加成功')
				}).catch(err=>{
					this.$message.error('添加失败')
				}).finally(()=>{
					this.cardLoading = false
				})
			},
			// 给新的组件 加入对象数据
			choose(id) {
				this.activeId = id
				let item = { ...this.editItem } // 对应的组件列表
				if(item && this[item.type]){
					if(Array.isArray(typeof item.data)){
						this[item.type].data = [...item.data]
					} else if(typeof item.data === 'object') {
						console.log(this[item.type])
						if(item.type === 'three'){
							this[item.type].title = item.data.title
							this[item.type].cover = item.data.cover
							return
						} 
						this[item.type].data = []
						for (let k in item.data) {
							let obj = {}
							switch (item.type){
								case 'indexnavs':
								obj = {
									src:item.data[k].src,
									text:item.data[k].text,
									id:item.data[k].id,
								}
									break;
								case 'list':
								obj = {
									id: item.data[k].id,
									cover: item.data[k].cover,
									title:item.data[k].title,
									desc: item.data[k].desc,
									oprice: item.data[k].oprice,
									pprice:item.data[k].pprice,
									srcs: item.data[k].srcs
								}
									break;	
								case 'listTwo':
								obj = {
									id: item.data[k].id,
									cover: item.data[k].cover,
									title:item.data[k].title,
									desc: item.data[k].desc,
									oprice: item.data[k].oprice,
									pprice:item.data[k].pprice,
									srcs: item.data[k].srcs
								}
									break;
								// case 'fzkb':
								// obj = {
								// }
								// 	break;
								default:
								obj = {
									src:item.data[k].src,
									id:item.data[k].id,
								}
									break;
							}
							this[item.type].data.push(obj)
						}
					}
				}
				// console.log(this.list);
			},
			submit(){
				let item = this.buttonItem
				let iis = this.value
				console.log(this.buttonItem)
				if(item.limit != 8 ){
					for(let i=0;i<item.data.length;i++){
						item.data[i].id = iis[i]
					}
				}
				
				
				if(item){
					let o = this.indexList[this.editIndex]
					let newData = {}
					if(this.editItem.type === 'three'){
						newData.title = item.title
						newData.cover = item.cover
					} else {
						item.data.forEach((s,si)=>{
							newData[si.toString()] = { ...s }
						})
					}
					this.axios.post('/admin/app_index_data/'+o.id,{
						data:JSON.stringify(newData)
					},{
						token:true
					}).then(res=>{
						o.data = newData
						this.$message.success('保存成功');
					}).catch(err=>{
						this.$message.error('保存失败');
					})
				}
			},
			chooseImage(index){
				this.app.chooseImage((res)=>{
					// if(this.editItem.type === 'three'){
					// 	return this.twoAdv.cover = res[0].url
					// }
					this[this.editItem.type].data[index].src = res[0].url
				},1)
			},
			deleteFormItem(index){
				this.$confirm('是否要删除?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this[this.editItem.type].data.splice(index,1)
				})
			},
			addFormItem(){
				let obj = {}
				if(this.editItem.type === 'list'){
					// 弹出选择商品
					this.$refs.goodsDialog.chooseGoods((res)=>{
						res.forEach(item=>{
							this[this.editItem.type].data.push({
								cover:item.cover,
								desc: item.desc,
								id: item.id,
								oprice: item.min_oprice,
								pprice:item.min_price,
								title: item.title,
								srcs: item.srcs
							})
						})
					},this.buttonItem.limit-this.buttonItem.data.length)
				} else if(this.editItem.type === 'listTwo'){
					// 弹出选择商品
					this.$refs.goodsDialog.chooseGoods((res)=>{
						res.forEach(item=>{
							this[this.editItem.type].data.push({
								cover: item.cover,
								desc: item.desc,
								id: item.id,
								oprice: item.min_oprice,
								pprice:item.min_price,
								title: item.title,
								srcs: item.srcs
							})
						})
					},this.buttonItem.limit-this.buttonItem.data.length)
				} else if(this.editItem.type === 'indexnavs'){
					this[this.editItem.type].data.push({
						src:"",
						text:"",
						id:"",
					})
				} else {
					this[this.editItem.type].data.push({
						src:"",
						id:""
					})
				}
			},
			deleteItem(li){
				this.$confirm('是否要删除?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let id = this.indexList[li].id
					this.axios.delete('/admin/app_index_data/' + id + '/delete', {
							token: true
						}).then(res => {
							this.$message.success('删除成功');
							this.indexList.splice(li,1);
						}).catch(err => {});
				})
			},
			moveUp(index){
				if(index === 0){
					return
				}
				$U.moveUp(this.indexList,index)
				this.sort()
			},
			moveDown(index){
				if(this.indexList.length === 0 || index === (this.indexList.length - 1)){
					return
				}
				$U.moveDown(this.indexList,index)
				this.sort()
			},
			sort(){
				let sortdata = this.indexList.map((o,i)=>{
					return {
						id:o.id,
						order:(i+1)
					}
				})
				this.cardLoading = true
				this.axios.post('/admin/app_index_data/sort',{
					sortdata:JSON.stringify(sortdata)
				},{
					token:true
				}).then(res=>{
					this.$message.success('排序成功')
				}).catch(err=>{
					this.$message.error('排序失败')
				}).finally(()=>{
					this.cardLoading = false
				})
			},
			// 打开模态框
			openModel(e = false) {
				this.editIndexx = -1;
				// 打开模态框
				this.createModels = true;
			},
			// 删除顶级页面
			deleteindex(index, id) {
				let that = this;
				this.axios
					.delete('/admin/app_index_category/' + id + '/delete', {
						token: true
					})
					.then(res => {
						this.$message.success('删除成功');
						that.appIndexCategory.splice(index, 1);
					})
					.catch(err => {});
			},
			// 修改顶级页面
			updateindex(name, id) {
				console.log(name);
				let data = { name: name };
				this.axios
					.post('/admin/app_index_category/' + id, data, {
						token: true
					})
					.then(res => {
						this.$message.success('修改成功');
					})
					.catch(err => {});
			},
			// 新增顶级页面
			saveindex() {
				let that = this;
				let data = { name: '顶级页面名称', order: 50, template: 'special' };
				this.axios
					.post('/admin/app_index_category', data, {
						token: true
					})
					.then(res => {
						this.$message.success('添加成功');
						that.appIndexCategory.push(data);
					})
					.catch(err => {});
			},
			// 关闭模态框
			submits() {
				this.createModels = false;
			},
		},
	}
</script>

<style>
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.app-index-item {
		cursor: pointer;
		border-width: 2px !important;
	}

	.app-index-item-active {
		border-color: #00a5a5 !important;
	}
	.app-index-icon-box{
		position: absolute;width: 90px;height: 30px;
		z-index: 100;
		right: 0px;
		top: 0;display: flex;flex-wrap: wrap;background-color: #FFFFFF;
	}
	.app-index-icon-btn{
		width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;
		cursor: pointer!important;
	}
	.app-index-icon-btn:hover{
		background-color: #eeeeee!important;
	}
	.app-index-icon-btn-disabled{
		cursor: not-allowed!important;
		background-color: #EEEEEE!important;
	}
</style>
